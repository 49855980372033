import { Currency } from '@shared/common';
import { PriceBase } from '@shared/common/src/types/general';

import { Product } from './useAppLead';
import { FirebaseFunction, useFirebaseQuery } from './useFirebase';

const DEFAULT_CURRENCY = Currency.USD;

export const useProducts = (product?: Product) => {
  const productsQuery = useFirebaseQuery<{ products: Product[] }>(FirebaseFunction.FETCH_PRODUCTS);
  const products = productsQuery.data?.data?.products || [];

  const findProductPrice = (currentProduct?: Product): PriceBase => {
    const priceBase = currentProduct?.prices?.find((price) => price.currency === DEFAULT_CURRENCY);

    return (
      priceBase || {
        price: 0,
        finalPrice: 0,
        currency: Currency.USD,
        discount: 0,
      }
    );
  };

  return {
    products,
    price: findProductPrice(product).price,
    finalPrice: findProductPrice(product).finalPrice,
    discount: findProductPrice(product).discount,
    product,
    currency: findProductPrice(product).currency,
    findProductPrice,
    findProductById: (productId: string) => products?.find((p) => p.id === productId),
  };
};
