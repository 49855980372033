import { useGTMEvents } from '@shared/common';

import { useAppLead } from './useAppLead';
import { FirebaseFunction, MutationOptions, useFirebaseMutation } from './useFirebase';

interface LeadData {
  id?: string;
  email?: string;
  quizAnswers?: Record<string, unknown>;
  country?: string;
  ip?: string;
  city?: string;
  customFields?: Record<string, unknown>;
}

interface LeadResponse {
  id: string;
  email: string;
}

export const useLeadMutation = (options?: MutationOptions<LeadResponse, LeadData>) => {
  const { updateUser } = useAppLead();
  const { trackLeadCreated } = useGTMEvents();

  const firebaseMutation = useFirebaseMutation<LeadResponse, LeadData>(FirebaseFunction.CREATE_LEAD, {
    ...options,
    onSuccess: (response, variables, context) => {
      const { id, email } = response.data;

      trackLeadCreated({ leadId: id });
      updateUser({ id, email });

      if (options?.onSuccess) {
        options.onSuccess(response, variables, context);
      }
    },
  });

  return firebaseMutation;
};
