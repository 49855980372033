import { PaymentProvider, PaymentsConfig } from '../types/general';
import { useElements, useStripe, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { FormEvent, useRef, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { SubmitButton } from '../components/SubmitButton';
import { useTranslation } from '../hooks/useTranslation';

let IS_SUBMITED = false;

const stripeInputOptions = { style: { base: { fontSize: '16px', '::placeholder': { color: '#748094' } } } };

export const StripeCustomForm = (props: PaymentsConfig) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const t = useTranslation(props.options?.locale || 'en-US');

  const handleStripeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (stripe && elements && !IS_SUBMITED) {
      setError('');
      setIsLoading(true);
      IS_SUBMITED = true;

      if (props.onSubmit) {
        await props.onSubmit({ paymentProvider: PaymentProvider.STRIPE });
      }

      const cardEl = elements.getElement(CardNumberElement);
      const order = await props.createOrder({
        paymentProvider: PaymentProvider.STRIPE,
      });

      if (cardEl) {
        const res = await stripe.confirmCardPayment(order.id as string, {
          payment_method: {
            card: cardEl,
            billing_details: {
              name: inputRef?.current?.value,
            },
          },
        });

        if (res.error) {
          setError(
            res.error.decline_code === 'do_not_honor' ||
              res.error.decline_code === 'generic_decline' ||
              res.error.decline_code === 'insufficient_funds'
              ? `Your card was declined by the card-issuing bank. You will need to contact them for more information, or alternatively, use another card or PayPal`
              : `${res.error.message} Please check the form details and try again, or alternatively, use another card or PayPal`,
          );
          setIsLoading(false);

          if (props.onError) {
            res.error.code;
            props.onError({ code: res.error.code });
          }
        } else {
          await props.onSuccess({
            id: (order as never as { subscriptionId: string }).subscriptionId || order.id,
            paymentProvider: PaymentProvider.STRIPE,
          });
        }

        IS_SUBMITED = false;
      }
    }
  };

  return (
    <form onSubmit={handleStripeSubmit}>
      {error && (
        <Text
          backgroundColor='#ffe4e4'
          padding='0.5rem 0.75rem'
          borderRadius='0.25rem'
          color='#e12a2a'
          fontSize='14px'
          marginBottom='0.5rem'
        >
          {error}
        </Text>
      )}
      <Text as='label' fontSize='14px' color='#30313d' marginBottom='0.25rem' fontFamily='inherit'>
        {t('name_on_card')}
      </Text>
      <Box
        ref={inputRef}
        as='input'
        required
        width='100%'
        padding='12px'
        fontSize='16px'
        type='text'
        name='name'
        marginBottom='12px'
        border='1px solid'
        borderColor='#e6e6e6'
        borderRadius='5px'
        placeholder='Jane Doe'
        fontWeight={400}
        outline='none'
        _focusVisible={{
          borderColor: '#F3EEE8',
          borderRadius: '0.25rem',
        }}
        fontFamily='inherit'
        backgroundColor='system.white'
        boxShadow='0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)'
      />
      <Text as='label' fontSize='14px' color='#30313d' marginBottom='0.25rem' fontFamily='inherit'>
        {t('card_number')}
      </Text>
      <Box
        padding='12px'
        backgroundColor='system.white'
        boxShadow='0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)'
        marginBottom='12px'
        border='1px solid'
        borderColor='#e6e6e6'
        borderRadius='5px'
      >
        <Flex gap='4px' alignItems='center'>
          <Flex>
            <CardIcon />
          </Flex>
          <Box flex={1}>
            <CardNumberElement options={stripeInputOptions} />
          </Box>
        </Flex>
      </Box>
      <Flex gap='12px' marginBottom='5px'>
        <Box flex={1}>
          <Text as='label' fontSize='14px' color='#30313d' marginBottom='0.25rem' fontFamily='inherit'>
            {t('expiration')}
          </Text>
          <Box
            padding='15px 12px'
            backgroundColor='system.white'
            boxShadow='0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)'
            marginBottom='12px'
            border='1px solid'
            borderColor='#e6e6e6'
            borderRadius='5px'
          >
            <CardExpiryElement options={stripeInputOptions} />
          </Box>
        </Box>
        <Box flex={1}>
          <Text as='label' fontSize='14px' color='#30313d' marginBottom='0.25rem' fontFamily='inherit'>
            CVC
          </Text>
          <Box
            padding='12px'
            backgroundColor='system.white'
            boxShadow='0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)'
            marginBottom='12px'
            border='1px solid'
            borderColor='#e6e6e6'
            borderRadius='5px'
          >
            <Flex alignItems='center' justifyContent='space-between'>
              <Box flex={1}>
                <CardCvcElement options={stripeInputOptions} />
              </Box>
              <CVCIcon />
            </Flex>
          </Box>
        </Box>
      </Flex>
      <SubmitButton title={t('submit_secure_payment')} isLoading={isLoading} />
    </form>
  );
};

const CVCIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    fill='var(--colorIconCardCvc)'
    role='img'
    aria-labelledby='cvcDesc'
  >
    <path
      opacity='.2'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.337 4A5.493 5.493 0 0013 8.5c0 1.33.472 2.55 1.257 3.5H4a1 1 0 00-1 1v1a1 1 0 001 1h16a1 1 0 001-1v-.6a5.526 5.526 0 002-1.737V18a2 2 0 01-2 2H3a2 2 0 01-2-2V6a2 2 0 012-2h12.337zm6.707.293c.239.202.46.424.662.663a2.01 2.01 0 00-.662-.663z'
    ></path>
    <path opacity='.4' fillRule='evenodd' clipRule='evenodd' d='M13.6 6a5.477 5.477 0 00-.578 3H1V6h12.6z'></path>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.5 14a5.5 5.5 0 110-11 5.5 5.5 0 010 11zm-2.184-7.779h-.621l-1.516.77v.786l1.202-.628v3.63h.943V6.22h-.008zm1.807.629c.448 0 .762.251.762.613 0 .393-.37.668-.904.668h-.235v.668h.283c.565 0 .95.282.95.691 0 .393-.377.66-.911.66-.393 0-.786-.126-1.194-.37v.786c.44.189.88.291 1.312.291 1.029 0 1.736-.526 1.736-1.288 0-.535-.33-.967-.88-1.14.472-.157.778-.573.778-1.045 0-.738-.652-1.241-1.595-1.241a3.143 3.143 0 00-1.234.267v.77c.378-.212.763-.33 1.132-.33zm3.394 1.713c.574 0 .974.338.974.778 0 .463-.4.785-.974.785-.346 0-.707-.11-1.076-.337v.809c.385.173.778.26 1.163.26.204 0 .392-.032.573-.08a4.313 4.313 0 00.644-2.262l-.015-.33a1.807 1.807 0 00-.967-.252 3 3 0 00-.448.032V6.944h1.132a4.423 4.423 0 00-.362-.723h-1.587v2.475a3.9 3.9 0 01.943-.133z'
    ></path>
  </svg>
);

const CardIcon = () => (
  <svg width='24' viewBox='0 0 32 21'>
    <g>
      <g>
        <g transform='translate(0 2)'>
          <path
            d='M26.58 19H2.42A2.4 2.4 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4 2.4 0 0 1 29 2.38v14.25A2.4 2.4 0 0 1 26.58 19zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z'
            opacity='.2'
          ></path>
          <path
            d='M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z'
            opacity='.3'
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
