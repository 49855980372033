import { Text, TextProps } from '@chakra-ui/react';
import { useCountDown } from '@shared/common';
import { ForwardedRef, forwardRef } from 'react';

const INITIAL_TIME = 900;

export const TimeBanner = forwardRef((props: TextProps, ref: ForwardedRef<HTMLParagraphElement>) => {
  const time = useCountDown(INITIAL_TIME);

  const minutes = Math.floor(time / 60);
  const seconds = time - 60 * minutes;

  return (
    <Text ref={ref} fontSize={{ base: '30px', md: '32px' }} fontWeight={700} {...props}>
      {minutes} : {seconds <= 9 ? `0${seconds}` : seconds}
    </Text>
  );
});

TimeBanner.displayName = 'TimeBanner';
