import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest, CanMakePaymentResult } from '@stripe/stripe-js';
import { FC, useEffect, useState } from 'react';
import { ErrorMessage } from '../components/ErrorMessage';
import { ERRORS } from '../constants';
import { CallbacksWithOptions, PaymentProvider } from '../types/general';

export const PaymentButtons: FC<CallbacksWithOptions> = ({ createOrder, onSubmit, onSuccess, onError, options }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>(undefined);
  const [error, setError] = useState('');
  const { currency, amount, title } = options || {};

  const renderPaymentButton = (pr: PaymentRequest, paymentResult: CanMakePaymentResult) => {
    setPaymentRequest(pr);
    const method = paymentResult.applePay ? 'applePay' : paymentResult.googlePay ? 'googlePay' : undefined;

    if (stripe) {
      pr.on('paymentmethod', async (event) => {
        if (onSubmit) {
          onSubmit({ paymentProvider: PaymentProvider.STRIPE, method });
        }

        const order = await createOrder({
          paymentProvider: PaymentProvider.STRIPE,
        });

        if ('error' in order) {
          setError(ERRORS.generic);
          return;
        }

        const res = await stripe.confirmCardPayment(order.id, {
          payment_method: event.paymentMethod.id,
          save_payment_method: true,
        });

        if (res.paymentIntent) {
          event.complete('success');

          if (res.paymentIntent.status === 'requires_action') {
            const { error } = await stripe.confirmCardPayment(order.id);

            if (error) {
              setError(`${error.message} Please try again or use another payment method instead.`);

              return;
            }
          }

          if (onSuccess) {
            onSuccess({
              id: res.paymentIntent.id,
              paymentProvider: PaymentProvider.STRIPE,
              method,
            });
          }
        } else {
          event.complete('fail');
          setError(`${res.error.message} Please try again or use another payment method instead.`);

          if (onError) {
            onError({
              paymentProvider: PaymentProvider.STRIPE,
              code: res.error.code,
              message: res.error.message,
              method,
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    if (stripe && currency && amount && title) {
      const pr = stripe.paymentRequest({
        country: 'LT',
        currency: currency.toLocaleLowerCase(),
        total: {
          label: title,
          amount: amount * 100,
        },
        requestPayerName: true,
        disableWallets: ['link'],
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          renderPaymentButton(pr, result);
        }
      });
    }
  }, [stripe]);

  return paymentRequest ? (
    <div style={{ marginBottom: '0.5rem' }}>
      <ErrorMessage error={error} />
      <PaymentRequestButtonElement options={{ paymentRequest }} />
    </div>
  ) : null;
};
