import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { useGTMEvents } from '@shared/common';
import Image from 'next/image';

import ArrowSVG from '~/assets/icons/arrow.svg';
import Age1835Image from '~/assets/images/18_35.webp';
import Age3650Image from '~/assets/images/36_50.webp';
import Age5164Image from '~/assets/images/51_64.webp';
import Age65Image from '~/assets/images/65.webp';
import { useAppRouter } from '~/hooks/useAppRouter';

import { useAppLead } from '../hooks/useAppLead';

export const AgeRangeSelection = () => {
  const { updateQuizAnswers } = useAppLead();
  const { trackQuizAnswer } = useGTMEvents();
  const { pushNextRoute } = useAppRouter('/quiz');

  const handleSelection = (ageRange: string) => {
    updateQuizAnswers({ ageRange });

    trackQuizAnswer({ category: 'ageRange', label: ageRange });

    pushNextRoute();
  };

  return (
    <Grid
      gap={{ base: '1.5rem', md: '2rem' }}
      width='100%'
      gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
    >
      <Box cursor='pointer' onClick={() => handleSelection('18-35')}>
        <Flex position='relative' width='100%' paddingBottom='100%'>
          <Image src={Age1835Image} fill style={{ objectFit: 'contain' }} priority placeholder='blur' alt='' />
        </Flex>
        <Flex
          gap='0.5rem'
          position='relative'
          backgroundColor='#C45F81'
          padding='0.625rem'
          borderRadius='2rem'
          justifyContent='center'
          alignItems='center'
          marginTop='-2rem'
          boxShadow='0 4px 4px rgba(0, 0, 0, 0.1)'
        >
          <Text fontWeight={700} color='white'>
            18 - 35
          </Text>
          <Flex transform='rotate(180deg)'>
            <ArrowSVG width='16px' fill='#fff' />
          </Flex>
        </Flex>
      </Box>
      <Box cursor='pointer' onClick={() => handleSelection('36-50')}>
        <Flex position='relative' width='100%' paddingBottom='100%'>
          <Image src={Age3650Image} fill style={{ objectFit: 'contain' }} priority placeholder='blur' alt='' />
        </Flex>
        <Flex
          gap='0.5rem'
          position='relative'
          padding='0.625rem'
          borderRadius='2rem'
          justifyContent='center'
          alignItems='center'
          backgroundColor='#C45F81'
          marginTop='-2rem'
          boxShadow='0 4px 4px rgba(0, 0, 0, 0.1)'
        >
          <Text fontWeight={700} color='white'>
            36 - 50
          </Text>
          <Flex transform='rotate(180deg)'>
            <ArrowSVG width='16px' fill='#fff' />
          </Flex>
        </Flex>
      </Box>
      <Box cursor='pointer' onClick={() => handleSelection('51-64')}>
        <Flex position='relative' width='100%' paddingBottom='100%'>
          <Image src={Age5164Image} fill style={{ objectFit: 'contain' }} priority placeholder='blur' alt='' />
        </Flex>
        <Flex
          gap='0.5rem'
          position='relative'
          padding='0.625rem'
          borderRadius='2rem'
          justifyContent='center'
          alignItems='center'
          backgroundColor='#C45F81'
          marginTop='-2rem'
          boxShadow='0 4px 4px rgba(0, 0, 0, 0.1)'
        >
          <Text fontWeight={700} color='white'>
            41 - 64
          </Text>
          <Flex transform='rotate(180deg)'>
            <ArrowSVG width='16px' fill='#fff' />
          </Flex>
        </Flex>
      </Box>
      <Box padding='2px' cursor='pointer' onClick={() => handleSelection('55+')}>
        <Flex position='relative' width='100%' paddingBottom='100%'>
          <Image src={Age65Image} fill style={{ objectFit: 'contain' }} priority placeholder='blur' alt='' />
        </Flex>
        <Flex
          gap='0.5rem'
          position='relative'
          padding='0.625rem'
          borderRadius='2rem'
          justifyContent='center'
          alignItems='center'
          backgroundColor='#C45F81'
          marginTop='-2rem'
          boxShadow='0 4px 4px rgba(0, 0, 0, 0.1)'
        >
          <Text fontWeight={700} color='white'>
            65+
          </Text>
          <Flex transform='rotate(180deg)'>
            <ArrowSVG width='16px' fill='#fff' />
          </Flex>
        </Flex>
      </Box>
    </Grid>
  );
};
