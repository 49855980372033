import { ReactNode, FC, memo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeConstructorOptions, StripeElementsOptions } from '@stripe/stripe-js';

interface Props {
  children: ReactNode;
  locale?: string;
  options?: StripeElementsOptions;
}

export const StripeWrapper: FC<Props> = memo(({ children, locale, options }: Props) => {
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY || '', {
    locale: locale as StripeConstructorOptions['locale'],
  });

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
});

StripeWrapper.displayName = 'StripeWrapper';
