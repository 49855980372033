import { Box, Flex, Text } from '@chakra-ui/react';
import { generalUtils } from '@shared/common';

import { useAppLead } from '~/hooks/useAppLead';
import { useProducts } from '~/hooks/useProducts';

export const OrderSummary = () => {
  const { selectedPlan: plan } = useAppLead();
  const { products } = useProducts();
  const selectedPlan = plan || products[2];
  const { finalPrice } = useProducts(selectedPlan);

  return (
    <Box marginBottom='0.5rem' bg='rgba(0, 0, 0, 0.05)' padding='1.5rem 1rem'>
      <Flex alignItems='center' justifyContent='space-between' gap='1rem' marginBottom='0.5rem'>
        <Text flex={1} fontSize='14px' color='#060654' maxWidth='16rem'>
          Personalized {plan?.title}
        </Text>
        <Text fontSize='14px' color='#060654' width='90px' textAlign='right'>
          {generalUtils.formatPrice(finalPrice, 'USD')}
        </Text>
      </Flex>
      {/* <Flex alignItems='center' justifyContent='space-between' gap='1rem'>
        <Text flex={1} fontSize='14px' fontWeight={400} color='#060654' maxWidth='16rem'>
          Value added Tax
        </Text>
        <Text fontSize='14px' color='#060654' fontWeight={400} width='90px' textAlign='right'>
          {tax.toFixed(2)} USD
        </Text>
      </Flex> */}
      <Flex
        justifyContent='space-between'
        gap='0.5rem'
        lineHeight='100%'
        paddingTop='1rem'
        marginTop='1rem'
        borderTop='1px solid rgba(0, 0, 0, 0.1)'
      >
        <Text fontSize='16px' color='#060654' fontWeight={700}>
          Total
        </Text>
        <Text fontSize='18px' color='#060654' fontWeight={700} lineHeight='100%'>
          {generalUtils.formatPrice(finalPrice, 'USD')}
        </Text>
      </Flex>
    </Box>
  );
};
