export const constants: { [key: string]: { [key: string]: string } } = {
  'en-US': {
    full_name: 'Full name',
    submit_secure_payment: 'Submit secure payment',
    or_pay_with_card: 'or pay with a card',
    credit_card: 'Credit Card',
    name_on_card: 'Name on card',
    card_number: 'Card number',
    expiration: 'Expiration',
  },
  'es-ES': {
    full_name: 'Nombre y apellidos',
    submit_secure_payment: 'Enviar pago seguro',
    or_pay_with_card: 'o pagar con tarjeta',
    credit_card: 'Tarjeta de crédito',
    name_on_card: 'Nombre en la tarjeta',
    card_number: 'Número de tarjeta',
    expiration: 'Caducidad',
  },
};

export const ERRORS = {
  generic: `Oopsie! Something went wrong with your payment. Please double-check your information and try again. If the issue persists, contact our support team. Thank you!`,
  missingPaymentMethod: `Oopsie! We couldn't process your payment with that card. Try another card or payment method. Thank you!`,
  loadingIssue: `Oopsie! Looks like we're facing issues with our current payment provider. Feel free to try another one for a smoother experience or refresh the page. If you need assistance or have any questions, reach out to our support team. Thanks for your understanding!`,
};

export const METHODS = {
  paypal: 'paypal',
};
