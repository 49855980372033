import { Box, Flex, Text } from '@chakra-ui/react';
import Image from 'next/image';

import TransitionArrow from '~/assets/icons/transition_arrow.svg';
import AfterImage from '~/assets/images/after.webp';
import BeforeImage from '~/assets/images/before.webp';

export const BeforeAfter = () => {
  return (
    <Box width='100%' backgroundColor='#FBEBF3' borderRadius='0.5rem' overflow='hidden' paddingTop='1rem'>
      <Flex alignItems='center' justifyContent='space-around' marginBottom='2rem'>
        <Text
          padding='0.5rem 1.5rem'
          backgroundColor='white'
          borderRadius='0.375rem'
          fontWeight={700}
          color='system.pink'
          lineHeight='100%'
          fontSize='14px'
        >
          NOW
        </Text>
        <Box width='1px' backgroundColor='system.pink' />
        <Text
          padding='0.5rem 1.5rem'
          backgroundColor='system.pink'
          borderRadius='0.375rem'
          fontWeight={700}
          color='system.white'
          lineHeight='100%'
          fontSize='14px'
        >
          Your Goal
        </Text>
      </Flex>
      <Flex position='relative' justifyContent='space-between'>
        <Flex position='relative' paddingBottom='40%' zIndex={2} flex={1} justifyContent='center'>
          <Image
            src={BeforeImage}
            alt=''
            style={{ objectFit: 'contain', objectPosition: 'bottom' }}
            fill
            priority
            placeholder='blur'
          />
        </Flex>
        <Flex position='relative' paddingBottom='40%' objectPosition='' zIndex={2} flex={1} justifyContent='center'>
          <Image
            src={AfterImage}
            alt=''
            style={{ objectFit: 'contain', objectPosition: 'bottom' }}
            fill
            priority
            placeholder='blur'
          />
        </Flex>
        <Flex position='absolute' top='50%' transform='translateY(-50%)' left='0' right='0' justifyContent='center'>
          <TransitionArrow fill='#ffffff' />
        </Flex>
      </Flex>
      <Flex padding='1rem' gap={{ base: '0.5rem', md: '1rem' }} backgroundColor='#F3F4F7'>
        <Box flex={1}>
          <Text fontWeight={700} marginBottom='0.125rem'>
            Body fat
          </Text>
          <Text
            marginBottom={{ base: '0.5rem', md: '1rem' }}
            paddingBottom={{ base: '0.5rem', md: '1rem' }}
            color='rgba(0, 0, 0, 0.74)'
            borderBottom='1px solid rgba(0, 0, 0, 0.1)'
            fontSize='14px'
          >
            High
          </Text>
          <Text fontWeight={700} marginBottom='0.125rem'>
            Wall pilates level
          </Text>
          <Text marginBottom='0.5rem' color='rgba(0, 0, 0, 0.74)' fontSize='14px'>
            Beginner
          </Text>
          <Flex gap='0.25rem'>
            <Box flex={1} borderRadius='2px' height='6px' backgroundColor='#DA6F93' />
            <Box flex={1} borderRadius='2px' height='6px' backgroundColor='#DA6F93' opacity={0.2} />
            <Box flex={1} borderRadius='2px' height='6px' backgroundColor='#DA6F93' opacity={0.2} />
          </Flex>
        </Box>
        <Box width='1px' backgroundColor='rgba(0, 0, 0, 0.1)' />
        <Box flex={1}>
          <Text fontWeight={700} marginBottom='0.125rem'>
            Body fat
          </Text>
          <Text
            marginBottom={{ base: '0.5rem', md: '1rem' }}
            paddingBottom={{ base: '0.5rem', md: '1rem' }}
            color='rgba(0, 0, 0, 0.74)'
            borderBottom='1px solid rgba(0, 0, 0, 0.1)'
            fontSize='14px'
          >
            Normal
          </Text>
          <Text fontWeight={700} marginBottom='0.125rem'>
            Wall pilates level
          </Text>
          <Text marginBottom='0.5rem' color='rgba(0, 0, 0, 0.74)' fontSize='14px'>
            Advanced
          </Text>
          <Flex gap='0.25rem'>
            <Box flex={1} borderRadius='2px' height='6px' backgroundColor='#DA6F93' />
            <Box flex={1} borderRadius='2px' height='6px' backgroundColor='#DA6F93' />
            <Box flex={1} borderRadius='2px' height='6px' backgroundColor='#DA6F93' />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
