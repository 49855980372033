import { Box, Flex, Text } from '@chakra-ui/react';
import { formulas } from '@shared/common';

import { useAppLead } from '~/hooks/useAppLead';

export const MainGoals = () => {
  const { quizAnswers } = useAppLead();
  const goals = quizAnswers?.goal;
  const mainGoal = /lose_weight/.test(goals || '')
    ? 'Lose weight'
    : goals?.split(goals || '')?.[0].replaceAll('_', '') || 'Lose weight';

  const targetWeight = quizAnswers?.isMetric
    ? Number(formulas.lbsToKg(Number(quizAnswers?.targetWeight)).toFixed(0))
    : Number(Number(quizAnswers?.targetWeight).toFixed(0));
  const unitSystem = quizAnswers?.isMetric ? 'kg' : 'lb';

  return (
    <Flex
      alignItems='stretch'
      justifyContent='space-between'
      gap={{ base: '0.5rem', md: '2rem' }}
      width='100%'
      sx={{
        svg: {
          width: { base: '34px', md: '44px' },
        },
      }}
    >
      <Flex flex={1} gap='0.75rem'>
        <svg viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g opacity='0.2'>
            <rect x='0.75' width='44' height='44' rx='9' fill='#C45F81'></rect>
          </g>
          <path
            d='M33.0566 22.3676C33.0566 20.0399 32.4297 17.8488 31.3186 15.9742L29.1647 18.1281C29.7916 19.413 30.1392 20.8406 30.1392 22.3676C30.1392 27.7554 25.757 32.1004 20.4064 32.1004C15.0496 32.1004 10.6674 27.7244 10.6674 22.3676C10.6674 17.017 15.0496 12.6348 20.4002 12.6348C21.9271 12.6348 23.3548 12.9824 24.6397 13.6093L26.7998 11.4554C24.9252 10.3443 22.7341 9.71741 20.4064 9.71741C13.4171 9.71741 7.75 15.3845 7.75 22.3676C7.75 26.1229 9.38248 29.4934 11.9895 31.7901L9.62456 37.2151C9.488 37.4944 9.51904 37.8048 9.69284 38.0841C9.86664 38.3262 10.146 38.5 10.4563 38.5H14.0006C14.3482 38.5 14.6958 38.2889 14.8324 37.9786L16.4648 34.3971C17.7187 34.8129 19.0346 35.024 20.394 35.024C21.7844 35.024 23.0693 34.7819 24.3231 34.3971L25.9556 37.9786C26.0921 38.2889 26.4397 38.5 26.7873 38.5H30.344C30.6544 38.5 30.9337 38.3634 31.1075 38.0841C31.2813 37.842 31.3186 37.4944 31.1758 37.2151L28.8109 31.7901C31.3869 29.4934 33.0566 26.1229 33.0566 22.3676Z'
            fill='#C45F81'
          ></path>
          <path
            d='M22.4173 15.8688C21.7903 15.6578 21.1324 15.5585 20.3999 15.5585C16.6446 15.5585 13.5845 18.6186 13.5845 22.3739C13.5845 26.1293 16.6446 29.1894 20.3999 29.1894C24.1553 29.1894 27.2154 26.1293 27.2154 22.3739C27.2154 21.6787 27.1099 21.0208 26.905 20.3938L22.6283 24.6706C22.0386 25.2603 21.2379 25.6079 20.3689 25.6079C19.5371 25.6079 18.7364 25.2603 18.1095 24.6706C16.8556 23.4167 16.8556 21.4056 18.1095 20.1518L22.4173 15.8688Z'
            fill='#C45F81'
          ></path>
          <path
            opacity='0.4'
            d='M37.1907 9.19607C37.0169 8.78019 36.6011 8.50087 36.1479 8.50087H34.2734V6.6263C34.2734 6.17318 33.9941 5.7573 33.5782 5.5835C33.1623 5.4097 32.6719 5.51522 32.3243 5.82558L29.5435 8.60639C29.3325 8.81743 29.1959 9.09055 29.1959 9.40711V11.6293L19.4321 21.4304C18.9107 21.9518 18.9107 22.8208 19.4321 23.3794C19.6741 23.6215 20.0217 23.7953 20.4066 23.7953C20.7542 23.7953 21.1018 23.6587 21.3811 23.3794L31.2133 13.5411H33.4354C33.7458 13.5411 34.0251 13.4045 34.2361 13.1935L37.0169 10.4127C37.2963 10.1023 37.4018 9.61195 37.1907 9.19607Z'
            fill='#C45F81'
          ></path>
        </svg>
        <Box>
          <Text fontSize='12px' color='rgba(0, 0, 0, 0.6)'>
            Main goal
          </Text>
          <Text fontWeight={700}>{mainGoal}</Text>
        </Box>
      </Flex>
      <Flex flex={1} gap='0.75rem'>
        <svg viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g opacity='0.2'>
            <rect x='0.75' width='44' height='44' rx='9' fill='#C45F81'></rect>
          </g>
          <path
            d='M36.7386 10.5447H31.9875C30.7199 6.75159 27.0593 4 22.7496 4C18.4377 4 14.7771 6.75159 13.5106 10.5447H8.75955C6.27637 10.5447 4.48692 12.51 4.7818 14.9102L7.3212 35.6334C7.61608 38.0347 9.88712 40 12.3703 40H33.1299C35.6121 40 37.8831 38.0358 38.178 35.6334L40.7184 14.9102C41.0123 12.51 39.2217 10.5447 36.7386 10.5447ZM22.7496 6.62346C26.6223 6.62346 29.7748 9.69329 29.7748 13.4668C29.7748 17.2403 26.6223 20.3101 22.7496 20.3101C18.8758 20.3101 15.7233 17.2403 15.7233 13.4668C15.7233 9.69329 18.8758 6.62346 22.7496 6.62346Z'
            fill='#C45F81'
          ></path>
          <path
            opacity='0.4'
            d='M22.1034 13.0086C21.7942 13.4275 21.6811 13.9037 21.7914 14.3156C21.8526 14.544 22.0138 14.8699 22.4373 15.121C22.8911 15.3936 23.2701 15.3546 23.5052 15.2741C23.8811 15.1481 24.1923 14.8242 24.3785 14.3614L26.1003 10.1081C26.2549 9.73068 26.1367 9.25389 25.8262 9.06914C25.4607 8.85081 25.0329 9.0467 24.8147 9.34236L22.1034 13.0086Z'
            fill='#C45F81'
          ></path>
        </svg>
        <Box>
          <Text fontSize='12px' color='rgba(0, 0, 0, 0.6)'>
            Target weight
          </Text>
          <Text fontWeight={700}>{`${targetWeight} ${unitSystem}`}</Text>
        </Box>
      </Flex>
    </Flex>
  );
};
