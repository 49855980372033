import { useRouter } from 'next/router';
import { useEffect } from 'react';

type AppRoute =
  | '/create-account'
  | '/success'
  | '/plans'
  | '/email'
  | '/summary'
  | '/checkout'
  | '/quiz'
  | '/name'
  | '/preview';

export const useAppRouter = (nextRoute?: AppRoute) => {
  const router = useRouter();

  const getDefaultQueryParams = () => {
    const { query } = router;
    const params: Record<string, string> = {};

    if (query.flowName) {
      params.flowName = query.flowName as string;
    }

    return params;
  };

  useEffect(() => {
    if (nextRoute && typeof nextRoute === 'string') {
      router.prefetch(nextRoute);
    }
  }, [router, nextRoute]);

  return {
    ...router,
    pushNextRoute: (queryParams?: Record<'flowName', string>) => {
      const defaultQueryParams = getDefaultQueryParams();

      if (nextRoute) {
        router.push(
          queryParams || defaultQueryParams
            ? { pathname: nextRoute, query: { ...defaultQueryParams, ...queryParams } }
            : nextRoute,
        );
      }
    },
    flowName: router.query.flowName as undefined | string,
  };
};
