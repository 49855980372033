import { Box, Flex, Text } from '@chakra-ui/react';
import { useGTMEvents, useTracking } from '@shared/common';
import { PaymentElement, PaymentProvider, PaymentsAccordion, StripeWrapper } from '@shared/payments';
import Image from 'next/image';

import CloseSVG from '~/assets/icons/close.svg';
import PaymentMethodsImage from '~/assets/images/payment_methods.png';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';
import { useSubscriptionMutation } from '~/hooks/useSubscriptionMutation';

import { OrderSummary } from './OrderSummary';

export const CheckoutForm = () => {
  const { id, selectedPlan: plan } = useAppLead();
  const { products, findProductPrice } = useProducts();
  const selectedPlan = plan || products[2];
  const { currency } = useProducts(selectedPlan);
  const { trackPaymentSubmit, trackPaymentCancellation } = useGTMEvents();
  const { trackPurchase } = useTracking();
  const { pushNextRoute, back } = useAppRouter('/success');
  const subscriptionMutation = useSubscriptionMutation();

  const handlePaymentsSuccess = () => {
    trackPurchase({
      id: `${id}_${selectedPlan?.id}`,
      finalPrice: findProductPrice(selectedPlan).finalPrice,
      currency,
      leadId: String(id),
      items: {
        key: String(selectedPlan?.key),
        name: String(selectedPlan?.name),
        finalPrice: findProductPrice(selectedPlan).finalPrice,
      },
    });

    pushNextRoute();
  };

  const handleBack = () => {
    back();
  };

  const handleOrderCreation = async ({ paymentProvider }: { paymentProvider: PaymentProvider }) => {
    const { data } = await subscriptionMutation.mutateAsync({
      leadId: String(id),
      planId: selectedPlan?.id as string,
      provider: paymentProvider,
    });

    return data;
  };

  return (
    <Box backgroundColor='white' borderRadius='0.25rem' width='100%' overflow='hidden'>
      <Flex padding='1rem' justifyContent='right' cursor='pointer' onClick={handleBack}>
        <CloseSVG />
      </Flex>
      <OrderSummary />
      <Box padding={{ base: '1rem', md: '1.5rem' }}>
        <Text fontSize='20px' fontWeight={700} lineHeight='100%' marginBottom='1rem' color='#060654'>
          Select a secure payment method
        </Text>
        <Box marginBottom='2rem'>
          <StripeWrapper>
            <PaymentsAccordion
              defaultActive='card'
              paypal={
                <PaymentElement.Paypal
                  createOrder={handleOrderCreation}
                  forcePPRerender={[id]}
                  onSuccess={handlePaymentsSuccess}
                  options={{ isSubscription: false }}
                  onSubmit={({ paymentProvider }) => {
                    trackPaymentSubmit(paymentProvider);
                  }}
                  onCancel={() => {
                    trackPaymentCancellation('cancelled', PaymentProvider.PAYPAL);
                  }}
                  onError={(err) => {
                    trackPaymentCancellation('error', PaymentProvider.PAYPAL, err?.code);
                  }}
                />
              }
              card={
                <PaymentElement.StripeCustomForm
                  createOrder={handleOrderCreation}
                  onSuccess={handlePaymentsSuccess}
                  onSubmit={({ paymentProvider }) => {
                    trackPaymentSubmit(paymentProvider);
                  }}
                  onError={(err) => {
                    trackPaymentCancellation('error', PaymentProvider.STRIPE, err?.code);
                  }}
                />
              }
            />
          </StripeWrapper>
        </Box>
        <Text textAlign='center' fontSize='12px' marginBottom='0.5rem' color='#060654'>
          GUARANTEED <b>SAFE</b> CHECKOUT
        </Text>
        <Flex justifyContent='center' marginBottom='1.5rem'>
          <Image src={PaymentMethodsImage} width={342} height={17} alt='' />
        </Flex>
      </Box>
    </Box>
  );
};
