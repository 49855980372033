import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';

import { config } from '../config';

export enum FirebaseFunction {
  CREATE_LEAD = 'createLead',
  UPDATE_LEAD = 'updateLead',
  FETCH_PRODUCTS = 'fetchProducts',
  FETCH_LEAD = 'fetchLead',
  CREATE_SUBSCRIPTION = 'createPaymentSecretsForOrder',
  CREATE_ACCOUNT = 'createAccount',
}

const firebaseHash = process?.env?.NEXT_PUBLIC_FIREBASE_HASH as string;

const getFirebaseUrl = (name: FirebaseFunction, hash: string) =>
  (config.IS_DEV
    ? `http://127.0.0.1:4002/pilates-staging/us-central1/${name}`
    : `https://${name.toLocaleLowerCase()}-${hash}-uc.a.run.app/`);

export type MutationOptions<TResponse, TVariables> =
  | Omit<
      UseMutationOptions<AxiosResponse<TResponse>, unknown, TVariables & { config?: AxiosRequestConfig }>,
      'mutationFn'
    >
  | undefined;

export type QueryOptions<TResponse> =
  | Omit<UseQueryOptions<AxiosResponse<TResponse>, unknown, AxiosResponse<TResponse>, FirebaseFunction[]>, 'queryKey'>
  | undefined;

export const useFirebaseMutation = <TData, TVariables>(
  functionName: FirebaseFunction,
  options?: MutationOptions<TData, TVariables>,
) => {
  return useMutation<AxiosResponse<TData>, unknown, TVariables & { config?: AxiosRequestConfig }, unknown>(
    ({ config, ...data }) => axios.post(getFirebaseUrl(functionName, firebaseHash), data, config),
    options,
  );
};

export const useFirebaseQuery = <TResponse, TData = unknown>(
  functionName: FirebaseFunction,
  params?: TData,
  options?: QueryOptions<TResponse>,
) => {
  return useQuery([functionName], {
    ...options,
    queryFn: () => {
      return axios(getFirebaseUrl(functionName, firebaseHash), { params });
    },
  });
};
