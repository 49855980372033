import { constants } from '../constants';

export const useTranslation = (locale?: keyof typeof constants) => {
  return (key: string): string => {
    return locale && constants[locale]
      ? constants[locale][key]
      : constants['en-US'][key]
      ? constants['en-US'][key]
      : key;
  };
};
