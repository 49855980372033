import { ProductBase, useLead } from '@shared/common';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { QuizAnswers } from '../types';

export type Product = Omit<ProductBase, 'plan'> & {
  plan: { interval: number };
};

interface Lead {
  id?: string;
  selectedPlan?: Product;
  email?: string;
  quizAnswers?: QuizAnswers;
}

export const useAppLead = () => {
  const leadData = useLead<Lead>();
  const { query, asPath, replace } = useRouter();
  const [selectedPlan, setSelectedPlan] = useState<Product | undefined>(undefined);

  useEffect(() => {
    if (query.leadCode) {
      const newQuery = query;

      delete newQuery.leadCode;
      delete newQuery.catchall;

      replace({ pathname: asPath.split('?')[0], query: newQuery }, undefined, { shallow: true });
    }
  }, [query, asPath, replace]);

  useEffect(() => {
    setSelectedPlan(leadData.lead.selectedPlan);
  }, [leadData]);

  return { ...leadData, selectedPlan };
};
