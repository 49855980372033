import { Box, Button, Flex, Text, keyframes } from '@chakra-ui/react';
import { useGTMEvents } from '@shared/common';
import { useState } from 'react';

import CheckSVG from '~/assets/icons/check.svg';
import UncheckSVG from '~/assets/icons/unchecked.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';

const secondPulse = keyframes`
  20% {box-shadow: 0 0 0 0 #C45F81;}
  40% {box-shadow: transparent 0px 0px 0px 14px}
`;

export const PlanSelection = () => {
  const { findProductById, findProductPrice } = useProducts();
  const { updateUser } = useAppLead();
  const { trackPlanSelection } = useGTMEvents();
  const { pushNextRoute } = useAppRouter('/checkout');
  const [planId, setPlanId] = useState<1 | 2 | 3>(2);

  const handleClick = () => {
    const product = findProductById(String(planId));
    const price = findProductPrice(product);

    updateUser({ selectedPlan: product });

    if (product) {
      trackPlanSelection({ category: product.title, label: price.finalPrice });
    }

    pushNextRoute();
  };

  return (
    <>
      <Box
        padding={{ base: '0.75rem 0.5rem', md: '1rem' }}
        border='2px solid'
        borderColor='#e9eaf2'
        borderRadius='0.5rem'
        width='100%'
        backgroundColor={planId === 1 ? 'system.white' : '#F3F4F7'}
        cursor='pointer'
        onClick={() => setPlanId(1)}
        boxShadow={planId === 1 ? '0 2px 6px #162a411a, 0 12px 24px #677d961a' : 'none'}
      >
        <Flex alignItems='center' gap='0.5rem'>
          {planId === 1 ? (
            <CheckSVG width='24px' height='24px' fill='#C45F81' />
          ) : (
            <UncheckSVG width='24px' height='24px' />
          )}
          <Flex flex={1} gap={{ base: '1rem', md: '1.5rem' }} justifyContent='space-between' alignItems='center'>
            <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
              <Box>
                <Text fontWeight={700} fontSize='16px' marginBottom='0.325rem' lineHeight='100%'>
                  7-DAY TRIAL
                </Text>
                <Flex gap='0.5rem' alignItems='center'>
                  <Text fontSize='13px' color='#d27878' textDecor='line-through'>
                    $10.49
                  </Text>
                  <Text fontSize='13px' color='rgba(5, 5, 23, 0.64)'>
                    $6.93
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex alignItems='end' gap='0.25rem'>
              <Flex alignItems='center'>
                <svg
                  fill={planId === 1 ? '#FBEBF3' : '#e9eaf2'}
                  height='50px'
                  viewBox='0 0 14 42'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M13.7994 1.57509C14.5559 0.582101 15.7327 -0.000732422 16.9811 -0.000732422H14C16.2091 -0.000732422 18 1.79013 18 3.99927V37.9993C18 40.2084 16.2091 41.9993 14 41.9993H16.9811C15.7327 41.9993 14.5559 41.4164 13.7994 40.4234L0.846989 23.4234C-0.244042 21.9915 -0.244042 20.0071 0.846989 18.5751L13.7994 1.57509Z'></path>
                </svg>
                <Flex
                  padding='0.5rem'
                  backgroundColor={planId === 1 ? '#FBEBF3' : '#e9eaf2'}
                  borderRadius='0 0.25rem 0.25rem 0'
                  gap={{ base: '0.125rem', md: '0.25rem' }}
                  alignItems='flex-start'
                >
                  <Text fontSize='12px'>$</Text>
                  <Text fontSize='30px' lineHeight='100%' fontWeight={700}>
                    0
                  </Text>
                  <Box>
                    <Text lineHeight='100%' fontWeight={700} fontSize='14px'>
                      99
                    </Text>
                    <Text lineHeight='100%' fontSize={{ base: '12px', md: '13px' }}>
                      per day
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Box
        border='2px solid'
        borderColor='#e9eaf2'
        borderRadius='0.5rem'
        width='100%'
        backgroundColor={planId === 2 ? 'system.white' : '#F3F4F7'}
        cursor='pointer'
        onClick={() => setPlanId(2)}
        boxShadow={planId === 2 ? '0 2px 6px #162a411a, 0 12px 24px #677d961a' : 'none'}
        overflow='hidden'
      >
        <Flex
          padding='4px'
          backgroundColor={planId === 2 ? '#39BD99' : '#DADBE0'}
          gap='0.25rem'
          justifyContent='center'
          alignItems='center'
        >
          <svg viewBox='0 0 16 16' width='14px' height='14px' fill='white'>
            <path d='M3.35718 13.4464H3.98218V5.32141H3.35718C3.02566 5.32141 2.70771 5.45311 2.47329 5.68753C2.23887 5.92195 2.10718 6.23989 2.10718 6.57141V12.1964C2.10718 12.5279 2.23887 12.8459 2.47329 13.0803C2.70771 13.3147 3.02566 13.4464 3.35718 13.4464ZM13.3572 5.32141H8.98218L9.68343 3.21641C9.746 3.02855 9.76304 2.8285 9.73316 2.63276C9.70328 2.43701 9.62732 2.25117 9.51154 2.09053C9.39576 1.9299 9.24348 1.79906 9.06723 1.70881C8.89098 1.61855 8.69582 1.57146 8.4978 1.57141H8.35718L5.23218 4.97016V13.4464H12.1072L14.5522 8.07391L14.6072 7.82141V6.57141C14.6072 6.23989 14.4755 5.92195 14.2411 5.68753C14.0066 5.45311 13.6887 5.32141 13.3572 5.32141Z'></path>
          </svg>
          <Text fontSize='12px' textAlign='center' fontWeight={700} color='white'>
            MOST POPULAR
          </Text>
        </Flex>
        <Box padding={{ base: '0.75rem 0.5rem', md: '1rem' }}>
          <Flex alignItems='center' gap='0.5rem'>
            {planId === 2 ? (
              <CheckSVG width='24px' height='24px' fill='#C45F81' />
            ) : (
              <UncheckSVG width='24px' height='24px' />
            )}
            <Flex flex={1} gap={{ base: '1rem', md: '1.5rem' }} justifyContent='space-between' alignItems='center'>
              <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
                <Box>
                  <Text fontWeight={700} fontSize='16px' marginBottom='0.325rem' lineHeight='100%'>
                    1-MONTH PLAN
                  </Text>
                  <Flex gap='0.5rem' alignItems='center'>
                    <Text fontSize='13px' color='#d27878' textDecor='line-through'>
                      $30.99
                    </Text>
                    <Text fontSize='13px' color='rgba(5, 5, 23, 0.64)'>
                      $15.19
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Flex alignItems='end' gap='0.25rem'>
                <Flex alignItems='center'>
                  <svg
                    fill={planId === 2 ? '#FBEBF3' : '#e9eaf2'}
                    height='50px'
                    viewBox='0 0 14 42'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M13.7994 1.57509C14.5559 0.582101 15.7327 -0.000732422 16.9811 -0.000732422H14C16.2091 -0.000732422 18 1.79013 18 3.99927V37.9993C18 40.2084 16.2091 41.9993 14 41.9993H16.9811C15.7327 41.9993 14.5559 41.4164 13.7994 40.4234L0.846989 23.4234C-0.244042 21.9915 -0.244042 20.0071 0.846989 18.5751L13.7994 1.57509Z'></path>
                  </svg>
                  <Flex
                    padding='0.5rem'
                    backgroundColor={planId === 2 ? '#FBEBF3' : '#e9eaf2'}
                    borderRadius='0 0.25rem 0.25rem 0'
                    gap={{ base: '0.125rem', md: '0.25rem' }}
                    alignItems='flex-start'
                  >
                    <Text fontSize='12px'>$</Text>
                    <Text fontSize='30px' lineHeight='100%' fontWeight={700}>
                      0
                    </Text>
                    <Box>
                      <Text lineHeight='100%' fontWeight={700} fontSize='14px'>
                        51
                      </Text>
                      <Text lineHeight='100%' fontSize={{ base: '12px', md: '13px' }}>
                        per day
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box
        padding={{ base: '0.75rem 0.5rem', md: '1rem' }}
        border='2px solid'
        borderColor='#e9eaf2'
        borderRadius='0.5rem'
        width='100%'
        backgroundColor={planId === 3 ? 'system.white' : '#F3F4F7'}
        cursor='pointer'
        onClick={() => setPlanId(3)}
        boxShadow={planId === 3 ? '0 2px 6px #162a411a, 0 12px 24px #677d961a' : 'none'}
        marginBottom='1rem'
      >
        <Flex alignItems='center' gap='0.5rem'>
          {planId === 3 ? (
            <CheckSVG width='24px' height='24px' fill='#C45F81' />
          ) : (
            <UncheckSVG width='24px' height='24px' />
          )}
          <Flex flex={1} gap={{ base: '1rem', md: '1.5rem' }} justifyContent='space-between' alignItems='center'>
            <Flex gap={{ base: '1rem', md: '1.5rem' }} flex='1' alignItems='center'>
              <Box>
                <Text fontWeight={700} fontSize='16px' marginBottom='0.325rem' lineHeight='100%'>
                  3-MONTH PLAN
                </Text>
                <Flex gap='0.5rem' alignItems='center'>
                  <Text fontSize='13px' color='#d27878' textDecor='line-through'>
                    $53.19
                  </Text>
                  <Text fontSize='13px' color='rgba(5, 5, 23, 0.64)'>
                    $25.99
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex alignItems='end' gap='0.25rem'>
              <Flex alignItems='center'>
                <svg
                  fill={planId === 3 ? '#FBEBF3' : '#e9eaf2'}
                  height='50px'
                  viewBox='0 0 14 42'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M13.7994 1.57509C14.5559 0.582101 15.7327 -0.000732422 16.9811 -0.000732422H14C16.2091 -0.000732422 18 1.79013 18 3.99927V37.9993C18 40.2084 16.2091 41.9993 14 41.9993H16.9811C15.7327 41.9993 14.5559 41.4164 13.7994 40.4234L0.846989 23.4234C-0.244042 21.9915 -0.244042 20.0071 0.846989 18.5751L13.7994 1.57509Z'></path>
                </svg>
                <Flex
                  padding='0.5rem'
                  backgroundColor={planId === 3 ? '#FBEBF3' : '#e9eaf2'}
                  borderRadius='0 0.25rem 0.25rem 0'
                  gap={{ base: '0.125rem', md: '0.25rem' }}
                  alignItems='flex-start'
                >
                  <Text fontSize='12px'>$</Text>
                  <Text fontSize='30px' lineHeight='100%' fontWeight={700}>
                    0
                  </Text>
                  <Box>
                    <Text lineHeight='100%' fontWeight={700} fontSize='14px'>
                      29
                    </Text>
                    <Text lineHeight='100%' fontSize={{ base: '12px', md: '13px' }}>
                      per day
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <Button
        marginBottom='2rem'
        animation={`8s ease 3s infinite normal none running ${secondPulse}`}
        width='100%'
        onClick={handleClick}
        fontWeight={700}
      >
        GET MY PLAN
      </Button>
      <Text textAlign='center' color='rgba(0, 0, 0, 0.6)' fontSize='12px' lineHeight='140%'>
        {planId === 1
          ? 'We’ve automatically applied the discount to your 7-day trial price. Please note that your subscription will be automatically renewed at the full price of $30.99 per 1 month at the end of your trial and will then be continuously renewed every 1 month until you cancel. To cancel, please navigate to the Subscription Page in your account.'
          : planId === 2
            ? 'We’ve automatically applied the discount to your first 1-month period. After the first 1 month, your subscription will be automatically renewed at the full price of $30.99 per 1 month and will then be continuously renewed every 1 month until you cancel. To cancel, please navigate to the Subscription Page in your account.'
            : `We’ve automatically applied the discount to your first 3-month period. After the first 3 months, your subscription will be automatically renewed at the full price of $53.19 per 3 months and will then be continuously renewed every 3 months until you cancel. To cancel, please navigate to the Subscription Page in your account.`}
      </Text>
    </>
  );
};
