import { Box, Button, Input } from '@chakra-ui/react';
import { FormEvent, useRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';

export const NameForm = () => {
  const { lead, updateQuizAnswers } = useAppLead();
  const { pushNextRoute } = useAppRouter('/preview');
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    updateQuizAnswers({ name: inputRef?.current?.value });

    pushNextRoute();
  };

  return (
    <Box width='100%'>
      <form onSubmit={handleSubmit}>
        <Input
          ref={inputRef}
          placeholder='Your name'
          type='name'
          required
          borderColor='rgba(0, 0, 0, 0.1)'
          fontSize='16px'
          padding='1.75rem 1.25rem'
          outline='none'
          boxShadow='none'
          backgroundColor='#f8f8f8'
          defaultValue={lead?.quizAnswers?.name || ''}
          marginBottom='1rem'
        />
        <Box
          position={{ base: 'fixed', md: 'static' }}
          bottom='0'
          left='0'
          right='0'
          padding={{ base: '1rem 6% 1.75rem 6%', md: '0' }}
          backgroundColor={{ base: 'rgba(255, 255,255, 0.08)', md: 'transparent' }}
          borderTop={{ base: '1px solid rgba(255, 255, 255, 0.16)', md: 'none' }}
          backdropBlur='blur(20px)'
          zIndex={2}
        >
          <Button as='button' gap='1rem' width='100%' type='submit'>
            Continue
          </Button>
        </Box>
      </form>
    </Box>
  );
};
