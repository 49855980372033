import { initPlasmicLoader } from '@plasmicapp/loader-nextjs';
import { Animate } from '@shared/animations';
import { Button } from '@shared/common';

import { AgeRangeSelection } from '~/components/AgeRangeSelection';
import { BeforeAfter } from '~/components/BeforeAfter';
import { CheckoutForm } from '~/components/CheckoutForm';
import { MainGoals } from '~/components/MainGoals';
import { PlanSelection } from '~/components/PlanSelection';
import { TimeBanner } from '~/components/TimeBanner';
import { EmailForm } from '~/layouts/EmailForm';
import { NameForm } from '~/layouts/NameForm';

export const PLASMIC = initPlasmicLoader({
  projects: [
    {
      id: process.env.NEXT_PUBLIC_PLASMIC_PROJECT_ID as string,
      token: process.env.NEXT_PUBLIC_PLASMIC_TOKEN as string,
    },
  ],
  preview: process.env.NODE_ENV === 'development',
});

PLASMIC.registerComponent(Button, {
  name: 'Button',
  props: {
    children: {
      type: 'slot',
    },
    size: {
      type: 'choice',
      options: ['big', 'small'],
      defaultValue: 'big',
    },
    variant: {
      type: 'choice',
      options: ['primary', 'pink'],
      defaultValue: 'primary',
    },
    action: {
      type: 'choice',
      options: ['navigate', 'scroll'],
      defaultValue: 'navigate',
    },
    value: {
      type: 'string',
    },
  },
});

PLASMIC.registerComponent(Animate, {
  name: 'Animate',
  props: {
    children: {
      type: 'slot',
    },
    variant: {
      type: 'choice',
      options: ['swipeBottom', 'opacity'],
      defaultValue: 'opacity',
    },
  },
});

PLASMIC.registerComponent(AgeRangeSelection, {
  name: 'AgeRangeSelection',
  props: {},
});

PLASMIC.registerComponent(EmailForm, {
  name: 'EmailForm',
  props: {},
});

PLASMIC.registerComponent(NameForm, {
  name: 'NameForm',
  props: {},
});

PLASMIC.registerComponent(TimeBanner, {
  name: 'TimeBanner',
  props: {},
});

PLASMIC.registerComponent(BeforeAfter, {
  name: 'BeforeAfter',
  props: {},
});

PLASMIC.registerComponent(MainGoals, {
  name: 'MainGoals',
  props: {},
});

PLASMIC.registerComponent(PlanSelection, {
  name: 'PlanSelection',
  props: {},
});

PLASMIC.registerComponent(CheckoutForm, {
  name: 'CheckoutForm',
  props: {},
});
