import { Input, Box, Button, Text, Flex } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { generalUtils } from '@shared/common';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MailCheck from 'react-mailcheck';
import * as yup from 'yup';

import { useProducts } from '~/hooks/useProducts';

import { useAppLead } from '../hooks/useAppLead';
import { useAppRouter } from '../hooks/useAppRouter';
import { useLeadMutation } from '../hooks/useLeadMutation';

export const EmailForm = () => {
  const { pushNextRoute, query } = useAppRouter('/preview');
  const [geolocation, setGeolocation] = useState({ country: '', ip: '', city: '' });
  const { id, lead, quizAnswers, updateUser } = useAppLead();
  const { findProductById } = useProducts();
  const leadMutation = useLeadMutation({
    onSuccess: () => {
      updateUser({ selectedPlan: findProductById('3') });

      pushNextRoute();
    },
    onError: () => {
      setError('email', { message: 'Oops! Something went wrong! Please try again.' });
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email('The email must be a valid email address')
          .required('Email field is required to fill'),
      }),
    ),
  });

  const handleFormSubmit = (email?: string) => {
    leadMutation.mutate({
      id,
      email,
      quizAnswers,
      country: geolocation.country || undefined,
      ip: geolocation.ip || undefined,
      city: geolocation.city,
      customFields: {
        ...generalUtils.getTrackingData(),
        flowName: query.flowName,
      },
    });
  };

  const fetchGeolocationData = async () => {
    const { data }: AxiosResponse<{ country: string; ip: string; city: string }> = await axios('/api/geolocation');

    setGeolocation(data);
  };

  useEffect(() => {
    fetchGeolocationData();
  }, []);

  return (
    <MailCheck email={watch('email') || ''}>
      {(suggestion: { full: string }) => (
        <Box as='form' onSubmit={handleSubmit((formData) => handleFormSubmit(formData.email))} width='100%'>
          <Box marginBottom={{ base: '0.5rem', md: '1rem' }}>
            <Flex position='relative' alignItems='center'>
              <Input
                placeholder='Your email address'
                type='email'
                required
                borderColor='rgba(0, 0, 0, 0.1)'
                fontSize='16px'
                padding='1.75rem 1.25rem'
                outline='none'
                boxShadow='none'
                backgroundColor='#f8f8f8'
                defaultValue={lead?.email || ''}
                {...register('email')}
              />
              <Flex position='absolute' right='12px' top='14px' zIndex={2}>
                <LockSVG />
              </Flex>
            </Flex>

            {suggestion?.full && (
              <Text
                fontSize='14px'
                textStyle='small'
                fontWeight={500}
                color='#9d9d9d'
                marginTop='0.5rem'
                cursor='pointer'
                onClick={() => setValue('email', suggestion.full)}
              >
                Did you mean <b>{suggestion.full}</b>?
              </Text>
            )}
            {errors?.email?.message && (
              <Text fontSize='14px' fontWeight={500} color='system.error' marginTop='0.5rem'>
                {errors?.email?.message as string}
              </Text>
            )}
          </Box>
          <Box
            position={{ base: 'fixed', md: 'static' }}
            bottom='0'
            left='0'
            right='0'
            padding={{ base: '1rem 6% 1.75rem 6%', md: '0' }}
            backgroundColor={{ base: 'rgba(255, 255,255, 0.08)', md: 'transparent' }}
            borderTop={{ base: '1px solid rgba(255, 255, 255, 0.16)', md: 'none' }}
            backdropBlur='blur(20px)'
            zIndex={2}
          >
            <Button
              as='button'
              gap='1rem'
              isLoading={leadMutation.status === 'loading'}
              width='100%'
              disabled={!watch('email')}
              type='submit'
            >
              Unlock my results
            </Button>
          </Box>
        </Box>
      )}
    </MailCheck>
  );
};

EmailForm.displayName = 'EmailForm';

const LockSVG = () => (
  <svg height='24' viewBox='0 0 48 48' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0h48v48H0z' fill='none' />
    <path
      fill='#828282'
      d='M36 16h-2v-4c0-5.52-4.48-10-10-10S14 6.48 14 12v4h-2c-2.21 0-4 1.79-4 4v20c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4V20c0-2.21-1.79-4-4-4zM24 34c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm6.2-18H17.8v-4c0-3.42 2.78-6.2 6.2-6.2 3.42 0 6.2 2.78 6.2 6.2v4z'
    />
  </svg>
);
